/*
 * @Author: DragonL
 * @Date: 2021-12-20 10:23:12
 * @LastEditors: DragonL
 * @LastEditTime: 2022-06-21 15:28:28
 * @Description: 
 */
import {createRouter,createWebHashHistory} from "vue-router"
// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。

// { label: "首页", value: 1, name: "home", enName: "HOME" },
// { label: "品牌中心", value: 2, name: "make", enName: "BRAND" },
// {
//   label: "产品中心",
//   value: 3,
//   name: "production",
//   enName: "PRODUCTION",
// },
// { label: "招聘中心", value: 4, name: "recruit", enName: "RECRUIT" },
// { label: "联系我们", value: 5, name: "contact", enName: "CONTACT" },
const routes = [
  { path: '/',name:"home", component: () => import('../views/index.vue') },
  { path: '/make',name:"make", component: () => import('../views/make.vue') },
  // { path: '/games',name:"games", component: () => import('../views/production.vue') },
  { path: '/recruit',name:"recruit", component: () => import('../views/recruit.vue') },
  { path: '/contact',name:"contact", component: () => import('../views/contact.vue') },
]

// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes, // `routes: routes` 的缩写
})



export default router