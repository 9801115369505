<template>
  <div class="header">
    <el-row class="">
      <el-col :xs="8" :sm="6" :md="4" :lg="4" :xl="8"
        ><div class="img">
          <img src="../../assets/header/logo.png" /></div
      ></el-col>
      <el-col :xs="16" :sm="18" :md="20" :lg="20" :xl="16"
        ><div class="tabs" ref="tabs">
          <div
            v-for="(item, index) in list"
            :key="item.value"
            :class="{
              act: $route.name === item.name,
              side: index !== list.length - 1,
            }"
            @click="focus(item, index)"
            @mouseenter="mouseenter(index)"
            @mouseleave="mouseleave(index)"
          >
            <transition-group name="fade">
              <!-- <div
                v-if="$route.name === item.name"
                class="moceng"
                key="1"
              ></div> -->
              <div class="label" key="2">{{ item.label }}</div>
              <img
                :src="item.img"
                class="ack"
                v-if="item.show || $route.name === item.name"
              />
              <!-- <div v-if="$route.name === item.name" class="background-act" key="3">
                {{ item.enName }}
              </div> -->
              <!-- <img key="4"
                v-if="$route.name === item.name"
                class="yellow"
                src="../../assets/icon/yellow.png"
              /> -->
            </transition-group>
          </div>
        </div></el-col
      >
    </el-row>
  </div>
</template>
<script>
// import { defineComponent } from "vue";
import { ElRow, ElCol } from "element-plus";
export default {
  name: "DrHeader",
  components: {
    ElRow,
    ElCol,
  },
  data() {
    return {
      list: [
        {
          label: "首页",
          value: 1,
          name: "home",
          enName: "HOME",
          img: require("../../assets/header/home.png"),
          show: false,
        },
        {
          label: "公司介绍",
          value: 2,
          name: "make",
          enName: "BRAND",
          img: require("../../assets/header/brand.png"),
          show: false,
        },
        // {
        //   label: "产品中心",
        //   value: 3,
        //   name: "games",
        //   enName: "GAMES",
        //   img: require("../../assets/header/produ.png"),
        //   show: false,
        // },
        {
          label: "招聘中心",
          value: 4,
          name: "recruit",
          enName: "RECRUIT",
          img: require("../../assets/header/recruit.png"),
          show: false,
        },
        {
          label: "联系我们",
          value: 5,
          name: "contact",
          enName: "CONTACT",
          img: require("../../assets/header/contact.png"),
          show: false,
        },
      ],
    };
  },
  methods: {
    focus(item, index) {
      console.warn(item, index);
      this.$router.replace({
        name: item.name,
      });
    },
    mouseenter(index) {
      this.list[index].show = true;
    },
    mouseleave(index) {
      this.list[index].show = false;
    },
  },
};
</script>
<style scoped>
.tabs {
  display: flex;
}

.side {
  border-right: 1px solid;
  border-image: -webkit-linear-gradient(white, #aaa, white) 1 10 1; /* 控制边框颜色渐变 */
  border-image: -moz-linear-gradient(white, #aaa, white) 1 10 1;
  border-image: linear-gradient(white, #aaa, white) 1 10 1; /* 标准的必须写在最后 */
}
.tabs div {
  width: 165px;
  height: 67px;
  line-height: 67px;
  font-size: 17px;
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;
  color: #212121;
  background-image: url("../../assets/header/bottom.png");
  text-align: center;
  cursor: pointer;
  position: relative;
  white-space: nowrap; /*不换行*/
  overflow: hidden;
}
.ack {
  position: absolute;
  left: 0;
  top: 0;
  width: 165px;
  height: 67px;
}
/* .tabs div:before {
  content: "";
  border-radius: 50%;
  background: black;
  opacity: 0;
  width: 100%;
  height: 67px;
  position: absolute;
  left: 0;
  margin: 0;
  bottom: 0px;
  display: inline-block;
  transition: all 0.5s;
}

.tabs div:hover:before {
  opacity: 0.5;
  border-radius: unset;
} */
.img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 67px;
}

.header {
  background-image: url("../../assets/header/bg.png");
  box-shadow: 0px 8px 18px 0px rgba(18, 16, 21, 0.33);
  background-position: center;
  background-attachment: fixed;

  background-size: cover;
}
.img img {
  width: 132px;
  height: 49px;
}

.act {
  font-family: HarmonyOS_Sans_SC;
  font-weight: 400;

  text-shadow: 2px 2px 5px rgba(18, 16, 21, 0.72);
}
.act .label {
  color: #ffffff !important;
  opacity: 0.8 !important;
}
.background-act {
  position: absolute !important;
  left: 0;
  top: 0;
  background-image: none !important;
  font-size: 40px !important;
  font-weight: 600 !important;
  font-style: oblique;
  color: transparent !important;
  background-color: rgba(15, 13, 13, 0.5) !important;
  -webkit-text-stroke: 1px #CC73FF;
  opacity: 1 !important;
}
.yellow {
  width: 15px;
  height: 9px;
  position: absolute;
  bottom: 5px;
  z-index: 4;
  left: calc(50% - 15px / 2);
}

.label {
  z-index: 5;
  background-image: none !important;
  transition: all 0.5s;
}
.label:hover {
  color: white !important;
  opacity: 1 !important;
}
.moceng {
  background-color: rgba(8, 8, 8, 0.85) !important;
  background-image: none !important;
  z-index: 4;
  position: absolute !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0 !important;
}
</style>