<!--
 * @Author: Lin Qi Ping
 * @Date: 2021-09-22 08:57:50
 * @LastEditors: DragonL
 * @LastEditTime: 2022-05-26 15:35:35
 * @Description: 
-->
<template>
  <div class="app">
    <dr-header />
    <router-view v-slot="{ Component }">
      <transition name="fadesddd">
        <component
          :is="Component"
          class="ddd"
          :class="{ gamesd: list.indexOf($route.name) !== -1 }"
        />
      </transition>
    </router-view>
    <dr-footer />
  </div>
</template>

<script>
import DrFooter from "../src/components/DrFooter/index.vue";
import DrHeader from "../src/components/DrHeader/index.vue";

export default {
  name: "App",
  data() {
    return {
      list: ["games", "recruit", "contact"],
    };
  },
  components: {
    DrHeader,
    DrFooter,
  },
};
</script>
<style scoped>
.app {
  height: auto;
  background-image: url("./assets/background/backgound.jpg");
  /* background-size: cover; */
  background-size: 100% 100%;
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.change {
  background-image: url("./assets/middle.png");
}
</style>
<style scoped>
.ddd {
  opacity: 1;
  /* min-height: 1980px; */
}
.gamesd {
  /* min-height: 833px; */
}
.fades-enter-active,
.fades-leave-active {
  transition: opacity 0.5s ease;
}

.fades-enter-from,
.fades-leave-to {
  opacity: 0;
}
</style>
<style >
body {
  margin: 0;
  padding: 0;
}

.title-enter-active,
.title-leave-active {
  transition: all 0.5s ease;
}

.title-enter-from,
.title-leave-to {
  opacity: 0;
}

body::-webkit-scrollbar {
  display: none;
}
</style>
