/*
 * @Author: DragonL
 * @Date: 2021-12-20 10:23:12
 * @LastEditors: DragonL
 * @LastEditTime: 2022-05-26 10:58:10
 * @Description: 
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from "./router/index"
import 'element-plus/dist/index.css'
// import vue3SeamlessScroll from "vue3-seamless-scroll";
import vueSeamlessScroll from '@zengxiaohui/vue3-seamless-scroll'
let app = createApp(App)
// document.title = "燃即正义！心源工作室官方网站"
app.use(vueSeamlessScroll)
app.use(router)
app.mount('#app')