<!--
 * @Author: DragonL
 * @Date: 2021-09-22 08:57:50
 * @LastEditors: DragonL
 * @LastEditTime: 2022-06-21 16:04:36
 * @Description: 
-->
<template>
  <div
    class="footer"
    :class="{ games: $route.name == 'games' || $route.name == 'contact' }"
  >
    <div class="footer-middle">
      <img class="footer-logo" src="../../assets/footer/logo.png" />
      <div class="footer-content">
        <div>
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
          >
            粤ICP备2022061248号
          </a>
        </div>
        <div>
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010502002285"
            style="
              display: flex;
              text-decoration: none;
            "
            class="ggf"
            ><img src="../../assets/beian.png" />
            <span style="">粤公网安备 44010502002285号</span></a
          >
        </div>
        <div>Copyright 2022 © 喀什奥术网络科技有限公司广州分公司版权所有.</div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.footer {
  height: 262px;
  opacity: 0.9;
  width: 100%;
  background-image: url("../../assets/footer/1.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}

.footer-logo {
  /* margin-left: 369px; */
  width: 270px;
  height: 98px;
  /* margin-top: 70px; */
}
.ggf {
  /* justify-content: center; */
  align-items: center;
}
.games {
  height: 321px;
}
.footer-content {
  /* margin-top: 64px; */
  margin-left: 91px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #ffffff;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
}

a:link {
  /* font-size: 12px; */
  color: #ffffff;
  text-decoration: none;
}
a:visited {
  /* font-size: 12px; */
  color: #ffffff;
  text-decoration: none;
}
a:hover {
  /* font-size: 12px; */
  color: #ffffff;
  text-decoration: underline;
}

.footer-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>